<template>
  <v-card
    light
    color="white"
    :loading="loading"
    tile
    height="100%"
    width="100%"
    class="d-flex flex-column"
  >
    <Loading_overlay v-model="loading" />
    <!--    <v-alert type="info" class="mb-0" tile v-if="loaderAppear"-->
    <!--      >Collecting-->
    <!--      {{-->
    <!--        Math.round(-->
    <!--          (loadingPerc.finished.length / loadingPerc.requestCount) * 100-->
    <!--        )-->
    <!--      }}-->
    <!--      %-->
    <!--      &lt;!&ndash;      <template v-slot:append>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <v-btn&ndash;&gt;-->
    <!--      &lt;!&ndash;          icon&ndash;&gt;-->
    <!--      &lt;!&ndash;          v-if="!report.loading"&ndash;&gt;-->
    <!--      &lt;!&ndash;          @click="$store.dispatch('fetchReports', true)"&ndash;&gt;-->
    <!--      &lt;!&ndash;        >&ndash;&gt;-->
    <!--      &lt;!&ndash;          <v-icon>fas fa-play</v-icon>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </v-btn>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <v-btn @click="$store.dispatch('pauseRequest')" icon v-else>&ndash;&gt;-->
    <!--      &lt;!&ndash;          <v-icon>fas fa-pause</v-icon>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </v-btn>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </template>&ndash;&gt;-->
    <!--    </v-alert>-->
    <Report_toolbar
      @refresh="$emit('refresh')"
      :reports="reports"
      :el="el"
      :elPdf="elPdf"
      style="    flex: 0.25;"
    />
    <div style="flex: 1 1 auto;">
      <template v-if="!loading && reports.length > 0">
        <All_collections :reports="reports" v-if="report.type === 0" />
        <Last_note :reports="reports" v-else-if="report.type === 1" />
        <Open_collections :reports="reports" v-else-if="report.type === 2" />
        <SummaryReport
          :reports="reports"
          :note="report.type === 4"
          v-else-if="report.type === 3 || report.type === 4"
        />
      </template>
    </div>

    <!--    <router-view />-->
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import storage from "@/storage";
import { customFilterArr, GetOnlyOpened } from "@/functions";
import Loading_overlay from "@/components/loading_overlay";
export default {
  name: "report",
  components: {
    Loading_overlay,
    SummaryReport: () => import("@/views/reports/summary"),
    Open_collections: () => import("@/views/reports/open_collections"),
    Last_note: () => import("@/views/reports/last_note"),
    All_collections: () => import("@/views/reports/all_collections"),
    Report_toolbar: () => import("@/components/report/report_toolbar")
  },
  data: () => ({
    el: null,
    elPdf: null,
    loading: false,
    reports: []
  }),
  updated() {
    this.el = document.getElementById("report");
    this.elPdf = document.getElementById("report_pdf");
  },
  mounted() {
    // this.startReports();
    this.$nextTick(() => {
      this.el = document.getElementById("report");
      this.elPdf = document.getElementById("report_pdf");
    });
  },
  methods: {
    async startReports() {
      this.loading = true;
      if (this.selected.AllAss) {
        this.reports = await storage.getAllReports(this.db);
        console.log(this.reports)
        let Fdefault = [];
        this.reports.map(r => {
          return (Fdefault = Fdefault.concat(...r.asses));
        });
        this.reports = Fdefault.map((r, i) => ({ ...r, id: i + 1 }));
      } else {
        this.reports = await storage.getCertainReports(
          this.selected.Client_Associations,
          this.db
        );
        this.reports = this.reports.map((r, i) => ({ ...r, id: i + 1 }));
      }
      this.getAssCount();
      // console.log(this.AllReports);
      // let last = {},reports = [];
      // this.AllReports.forEach((ass) => {
      //   if (
      //     last["Client_Associations"] === ass["Client_Associations"] &&
      //     last["Home Owner"] === ass["Home Owner"] &&
      //     last["Client Address"] === ass["Client Address"] &&
      //     last["Client Phone"] === ass["Client Phone"]
      //   ) {
      //     // will merge them
      //     reports[last.index].payments.push(...ass.payments);
      //   }
      //   else{
      //     last = ass;
      //     last.index = reports.length;
      //     reports.push(ass);
      //   }
      // });
      // console.log(reports);
      // console.log(
      //   this.AllReports.filter((ass, i, arr) => {
      //     const pI = arr.findIndex(
      //       r =>
      //         r["Client_Associations"] === ass["Client_Associations"] &&
      //         r["Home Owner"] === ass["Home Owner"] &&
      //         r["Client Address"] === ass["Client Address"] &&
      //         r["Client Phone"] === ass["Client Phone"]
      //     );
      //     return pI !== i;
      //   })
      // );
      // this.AllReports.forEach((ass, i, arr) => {
      //   const t = arr.findIndex(
      //     r =>
      //       r["Client_Associations"] === ass["Client_Associations"] &&
      //       r["Home Owner"] === ass["Home Owner"] &&
      //       r["Client Address"] === ass["Client Address"] &&
      //       r["Client Phone"] === ass["Client Phone"]
      //   );
      //   console.log(i, t, t === i);
      // });
      // let sum = 0;
      // this.AllReports.forEach(r => {
      //   sum = sum + 1;
      //   r.payments.forEach(() => {
      //     sum = sum + 1;
      //     if (sum % 30000 === 0) {
      //       console.log(r);
      //     }
      //   });
      // });
      // console.log(sum);
      if (this.$route.params.id) {
        this.goToReport();
      }
      this.loading = false;
    },
    goToReport() {
      if (
        !this.$route.params.id &&
        Object.keys(this.$route.params.id).length > 0
      )
        return;
      const ass = this.$route.params.id;
      const index = this.reports.findIndex(
        r =>
        r["Matter Number"] === ass["Matter Number"] 
        &&
          r["Client_Associations"] === ass["Client_Associations"] &&
          r["Home Owner"] === ass["Home Owner"] &&
          r["Client Address"] === ass["Client Address"] &&
          r["Client Phone"] === ass["Client Phone"]
      );
      window.scrollTo(0, 0);
      this.$store.commit("reportType", 0);
      this.$store.commit("reportIndex", index);
    },
    getAssCount() {
      let Count = 0;
      if (this.report.type === 0) {
        Count = this.reports.length;
      } else if (this.report.type === 1 || this.report.type === 2) {
        Count = GetOnlyOpened(this.reports).length;
      } else {
        Count = customFilterArr(this.reports, "Client_Associations").length;
      }
      this.$store.commit("reportCount", Count);
    }
  },
  computed: {
    fetched() {
      return this.report.fetched;
    },
    // loaderAppear() {
    //   if (!this.selected.AllAss) {
    //     return false;
    //   }
    //   return (
    //     this.loadingPerc.finished.length / this.loadingPerc.requestCount !==
    //       1 &&
    //     (this.loadingPerc.finished.length / this.loadingPerc.requestCount) *
    //       100 <
    //       100
    //   );
    // },
    ...mapGetters({
      db: "laudergetdb",
      selected: "lauderGetSelectedAss",
      isMgmt: "isMgmt",
      report: "report"
    })
  },
  watch: {
    loaderAppear: {
      handler() {
        this.startReports();
      }
    },
    selected: {
      immediate: true,
      handler() {
        this.startReports();
      }
    },
    "report.type": {
      immediate: true,
      handler() {
        this.getAssCount();
      }
    }
  }
};
</script>

<style lang="scss">
span.highlight {
  background: mediumvioletred !important;
}
</style>
